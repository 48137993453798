import { LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { SharedService } from './services/shared.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticationInterceptor } from './middlewares/interceptor/authen.interceptor';
import { registerLocaleData } from '@angular/common';

import localeTh from '@angular/common/locales/th';
import { DialogPopupComponent } from './modules/dialogs/dialog-popup/dialog-popup.component';
registerLocaleData(localeTh)

@NgModule({
  declarations: [
    AppComponent,
    DialogPopupComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
  ],
  providers: [
    SharedService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: "th-TH" }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
