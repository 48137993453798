import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog-error',
  templateUrl: './dialog-error.component.html',
  styleUrls: ['./dialog-error.component.css']
})
export class DialogErrorComponent implements OnInit {
  
  error: string = "";
  messageEn: string = "";
  messageTh: string = "";

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
