import { Component } from '@angular/core';
import { LoadingService } from './services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'kripton';

  constructor(private loading:LoadingService) {
    // this.loading.setLoading(true);
    // setTimeout(() => {
    //   this.loading.setLoading(false);
    // }, 1000);
  }
}
