import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenGuard } from './middlewares/guard/authen.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren : () => import('./layouts/layouts.module').then(m => m.LayoutsModule),
    canActivate: [
      AuthenGuard
    ]
  },
  {
    path: 'reportpdf',
    loadChildren : () => import('./modules/reportpdf/reportpdf.module').then(m => m.ReportpdfModule),
    canActivate: [
      AuthenGuard
    ]
  },
  {
    path: 'authen',
    loadChildren : () => import('./modules/authen/authen.module').then(m => m.AuthenModule)
  },
  {
    path: 'error',
    loadChildren : () => import('./modules/error/error.module').then(m => m.ErrorModule)
  },
  {
    path: '**',
    redirectTo: 'error/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
