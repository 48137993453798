import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  Observable,
  catchError,
  filter,
  finalize,
  map,
  merge,
  of,
  retry,
  switchMap,
  throwError,
} from 'rxjs';
import { AuthenService } from 'src/app/services/authen.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogErrorComponent } from 'src/app/modules/dialogs/dialog-error/dialog-error.component';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  constructor(
    private _modalService: NgbModal,
    private _authenService: AuthenService,
    private _loadingService: LoadingService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.handleRequest(req, next);
  }

  public handleRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.totalRequests++;
    this._loadingService.setLoading(true);
    const handleToken$ = (httpReq: HttpRequest<any>) =>
      next.handle(httpReq).pipe(
        catchError((error: ErrorEvent | HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              this._authenService.logout();
            } else if (error.status === 400) {
              var modalRef = this._modalService.open(DialogErrorComponent, {
                centered: true,
                size: 'md',
              });
              modalRef.componentInstance.error = error.error;
              modalRef.componentInstance.messageEn =
                error.error.error_description;
              modalRef.componentInstance.messageTh =
                error.error.error_description_th;
            }
          }
          this.totalRequests = 0;
          this._loadingService.setLoading(false);
          return throwError(() => error.error);
        }),
        finalize(() => {
          // this.totalRequests--;
          // if (this.totalRequests == 0) {
          //   this._loadingService.setLoading(false);
          // }
          this._loadingService.setLoading(false);
        })
      );

    const token$: Observable<boolean> = of(this._authenService.getToken()).pipe(
      filter((token) => !!token),
      map((token) => this.validateTokenExpire(token!))
    );

    //token is expired => refresh token
    const tokenIsExpired$ = token$.pipe(
      filter((isExpired) => isExpired),
      switchMap(() => this._authenService.refreshToken()),
      catchError((error) => {
        this._authenService.logout();
        return throwError(() => error);
      }),
      map((token) => {
        console.log(token);
        this._authenService.setToken(token.access_token);
        this._authenService.setRefreshToken(token.refresh_token);
        return token.access_token;
      }),
      map((token) => this.handleHeaderToken(req, token)),
      switchMap(handleToken$)
    );

    //token is not expired => add token to header
    const tokenIsNotExpired$ = token$.pipe(
      filter((isExpired) => !isExpired),
      map(() => this._authenService.getToken()),
      map((token) => this.handleHeaderToken(req, token!)),
      switchMap(handleToken$)
    );

    //token is not exists => handle token
    const tokenIsNotExists$ = of(req.url).pipe(
      filter((url) => url.includes('connect/token')),
      switchMap(() => handleToken$(req))
    );

    return merge(tokenIsExpired$, tokenIsNotExpired$, tokenIsNotExists$);
  }

  private handleHeaderToken(req: HttpRequest<any>, token: string) {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private validateTokenExpire(token: string): boolean {
    let profile = this._authenService.decodeToken(token);
    let tokenExpDate = profile.exp * 1000;
    let currentTime = new Date();
    let diffExpireTime = 300000;

    return diffExpireTime > tokenExpDate - Number(currentTime);
  }
}
